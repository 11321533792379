import { useDispatcher } from '@biceps/dispatcher'
import { useMemo } from 'react'

export const useShowcase = () => {
  const { dispatch, subscribe } = useDispatcher(true)

  const fetchAndAppend = useMemo(
    () =>
      ({ host, apiUrl }: { host: HTMLElement; apiUrl: string }) => {
        dispatch({
          type: 'onFetchShowcase',
          detail: { apiUrl },
        })

        subscribe({
          type: 'onFetchShowcaseDone',
          callback: ({ src }) => {
            const script = document.createElement('script')

            script.src = src.url
            script.async = true
            script.type = 'text/javascript'

            if (process.env.REACT_APP_ENV === 'prd') {
              host.shadowRoot?.appendChild(script)
            } else {
              host.appendChild(script)
            }
          },
        })
      },
    [dispatch, subscribe]
  )

  return { fetchAndAppend }
}
