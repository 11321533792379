import { useDispatcher } from '@biceps/dispatcher'
import { FC, useEffect, useState } from 'react'

export const Loader: FC = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { subscribe } = useDispatcher()

  useEffect(() => {
    subscribe({
      type: 'onFetchCompositionDone',
      callback: () => {
        setIsLoading(false)
      },
    })
  })

  return isLoading ? <h1>Loading...</h1> : null
}
