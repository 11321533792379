import { FC, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { Loader } from './components/Loader'
import { useShowcase } from './hooks/useShowcase'

const initEngine = () => {
  const host = document.querySelector('biceps-host') as HTMLElement
  const engine = document.createElement('biceps-engine')
  const showcase = document.createElement('biceps-showcase')

  host.setAttribute('style', 'all: initial; width: 100%')
  engine.setAttribute('style', 'display: block')
  showcase.setAttribute('style', 'display: block')

  if (process.env.REACT_APP_ENV === 'prd') {
    const shadow = host.attachShadow({ mode: 'open' })

    shadow.appendChild(engine)
    shadow.appendChild(showcase)
  } else {
    host.appendChild(engine)
    host.appendChild(showcase)
  }

  const App: FC = _ => {
    const { fetchAndAppend } = useShowcase()

    useEffect(() => {
      fetchAndAppend({ host, apiUrl: process.env.REACT_APP_API_URL })
    }, [fetchAndAppend])

    return <Loader />
  }

  createRoot(engine).render(<App />)
}

window.addEventListener('load', initEngine)
