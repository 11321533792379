import axios from 'axios'
import qs from 'qs'
import { useEffect, useMemo } from 'react'
import { BiCePS } from '../@types/biceps'

export const useDispatcher = (isMaster?: boolean) => {
  const guid = useMemo(
    () =>
      document.querySelector('biceps-host')?.getAttribute('data-guid') ||
      qs.parse(document.location.search.slice(1))['biceps-guid']?.toString(),
    []
  )

  const dispatch = useMemo(
    () =>
      <T extends keyof BiCePS.EventMap>(event: {
        type: T
        detail?: BiCePS.EventMap[T]['detail']
      }) => {
        dispatchEvent(new CustomEvent(event.type, { detail: event.detail }))
      },
    []
  )

  const subscribe = useMemo(
    () =>
      <T extends keyof BiCePS.EventMap>(event: {
        type: T
        callback: (detail: BiCePS.EventMap[T]['detail']) => void
      }) => {
        addEventListener(event.type, ((e: BiCePS.EventMap[T]) =>
          event.callback(e.detail)) as EventListener)
      },
    []
  )

  useEffect(() => {
    if (!isMaster) return

    subscribe({
      type: 'onFetchShowcase',
      callback: ({ apiUrl }) => {
        axios
          .get<{ url: string }>(`${apiUrl}/virtual-showcases/retail/${guid}/js`)
          .then(response => {
            dispatch({
              type: 'onFetchShowcaseDone',
              detail: { src: response.data },
            })
          })
      },
    })

    subscribe({
      type: 'onFetchComposition',
      callback: ({ apiUrl }) => {
        axios
          .get<BiCePS.Composition>(`${apiUrl}/virtual-showcases/retail/${guid}`)
          .then(response => {
            dispatch({
              type: 'onFetchCompositionDone',
              detail: { composition: response.data },
            })
          })
      },
    })
  }, [subscribe])

  return { dispatch, subscribe }
}
